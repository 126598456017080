import './WhiskeyEdit.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    getFirestore,
    collection,
    setDoc,
    getDoc,
    doc,
    Timestamp,
} from 'firebase/firestore';
import { useHistory } from 'react-router-dom';

const WhiskeyEdit = () => {
    const { uid } = useParams();
    const db = getFirestore();
    const history = useHistory();

    const [whiskey, setWhiskey] = useState([]);
    const whiskeyCollection = collection(db, 'whiskeys');

    useEffect(() => {
        getWhiskey().then((whiskey) => {
            let ts_to_date = new Date(whiskey.taste_date.toDate());
            whiskey.taste_date = asUtcIsoString(ts_to_date);
            setWhiskey(whiskey);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const asUtcIsoString = (date) => {
        const y = date.getUTCFullYear();
        const m = `${date.getUTCMonth() + 1}`.padStart(2, '0');
        const d = `${date.getUTCDate()}`.padStart(2, '0');
        return `${y}-${m}-${d}`;
    };

    const getWhiskey = async () => {
        const whiskeyCollection = doc(db, 'whiskeys', uid);
        const whiskeySnapshot = await getDoc(whiskeyCollection);
        return whiskeySnapshot.data();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        whiskey.last_updated = Timestamp.now();
        whiskey.taste_date = Timestamp.fromDate(new Date(whiskey.taste_date));
        setDoc(doc(whiskeyCollection, whiskey.uid), whiskey)
            .then(() => history.goBack())
            .catch((err) => console.error('WhiskeyEdit.handleSubmit() Error::', err));
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setWhiskey((values) => ({ ...values, [name]: value }));
    };

    // const backButton = () => {
    //     history.push('/whiskey');
    // };

    return (
        <>
            <form onSubmit={handleSubmit} className="whiskey-create-form">
                {/* UID (also pretty url) */}
                <label>UID</label>
                <input
                    type="text"
                    name="uid"
                    value={whiskey.uid || ''}
                    onChange={handleChange}
                />

                {/* Name */}
                <label>Name</label>
                <input
                    type="text"
                    name="name"
                    value={whiskey.name || ''}
                    onChange={handleChange}
                />

                {/* Page Number */}
                <label>Page Number</label>
                <input
                    type="text"
                    name="page_number"
                    value={whiskey.page_number || ''}
                    onChange={handleChange}
                />

                {/* Taste Date */}
                <label>Taste Date</label>
                <input
                    type="date"
                    name="taste_date"
                    value={whiskey.taste_date || ''}
                    onChange={handleChange}
                />

                {/* ABV */}
                <label>ABV</label>
                <input
                    type="text"
                    name="abv"
                    value={whiskey.abv || ''}
                    onChange={handleChange}
                />

                {/* Appearance */}
                <label>Appearance</label>
                <input
                    type="text"
                    name="appearance"
                    value={whiskey.appearance || ''}
                    onChange={handleChange}
                />

                {/* Nose */}
                <label>Nose</label>
                <input
                    type="text"
                    name="nose"
                    value={whiskey.nose || ''}
                    onChange={handleChange}
                />

                {/* Palate */}
                <label>Palate</label>
                <input
                    type="text"
                    name="palate"
                    value={whiskey.palate || ''}
                    onChange={handleChange}
                />

                {/* Finish */}
                <label>Finish</label>
                <input
                    type="text"
                    name="finish"
                    value={whiskey.finish || ''}
                    onChange={handleChange}
                />

                {/* Additional Notes */}
                <label>Additional Notes</label>
                <input
                    type="text"
                    name="add_notes"
                    value={whiskey.add_notes || ''}
                    onChange={handleChange}
                />

                <button className="whiskey-add-button" type="submit">
                    Submit
                </button>
            </form>
        </>
    );
};

export default WhiskeyEdit;
