import './BlogEdit.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    getFirestore,
    collection,
    setDoc,
    getDoc,
    doc,
    Timestamp,
} from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import RichTextEditor from '../../utils/rich-text-editor/RichTextEditor';

const BlogEdit = () => {
    const { uid } = useParams();
    const db = getFirestore();
    const history = useHistory();

    const [markup, setMarkup] = useState({});
    const [blog, setBlog] = useState({});
    const [loading, isLoading] = useState(true);
    const blogCollection = collection(db, 'blogs');

    useEffect(() => {
        getBlog().then((blog) => {
            setBlog(blog);
            isLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBlog = async () => {
        const blogCollection = doc(db, 'blogs', uid);
        const blogSnapshot = await getDoc(blogCollection);
        return blogSnapshot.data();
    };

    const handleEditorCallback = (editorMarkup) => {
        setMarkup(editorMarkup);
        setBlog({ ...blog, content: editorMarkup });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        blog.last_updated = Timestamp.now();
        setDoc(doc(blogCollection, blog.uid), blog)
            .then(() => history.goBack())
            .catch((err) =>
                console.error('BlogEdit.handleSubmit() Error::', err)
            );
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setBlog((values) => ({ ...values, [name]: value }));
    };

    // const backButton = () => {
    //     history.push('/blog');
    // };

    return (
        <>
            <form onSubmit={handleSubmit} className="blog-create-form">
                {/* UID (also pretty url) */}
                <label>UID</label>
                <input
                    type="text"
                    name="uid"
                    value={blog.uid || ''}
                    onChange={handleChange}
                />

                {/* Title */}
                <label>Title</label>
                <input
                    type="text"
                    name="title"
                    value={blog.title || ''}
                    onChange={handleChange}
                />

                {/* Description */}
                <label>Description</label>
                <input
                    type="text"
                    name="description"
                    value={blog.description || ''}
                    onChange={handleChange}
                />
                {!loading && (
                    <RichTextEditor
                        parentCallback={handleEditorCallback}
                        contentMarkup={blog.content}
                    />
                )}
                <button className="blog-add-button" type="submit">
                    Submit
                </button>
            </form>
        </>
    );
};

export default BlogEdit;
