import './Whiskeys.css';
import { useState, useEffect } from 'react';
import {
    getFirestore,
    collection,
    getDocs,
    query,
    orderBy,
    startAt,
    startAfter,
    endBefore,
    limit,
} from 'firebase/firestore';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

const Whiskeys = () => {
    const [whiskeys, setWhiskeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [firstDoc, setFirstDoc] = useState();
    const [lastDoc, setLastDoc] = useState();
    const [previousPage, setPreviousPage] = useState(1);
    const db = getFirestore();
    const whiskeyCollection = collection(db, 'whiskeys');

    const columns = [
        {
            name: 'Name',
            selector: (whiskey) => whiskey.name,
            sortable: true,
            cell: (whiskey) => {
                return (
                    <Link
                        className="whiskeys-link"
                        to={`/whiskey/${whiskey.uid}`}
                    >
                        {whiskey.name}
                    </Link>
                );
            },
        },
        {
            name: 'Taste Date',
            selector: (whiskey) =>
                new Date(whiskey.taste_date?.toDate()).toLocaleDateString(),
            sortable: true,
        },
    ];

    useEffect(() => {
        getWhiskeys(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getWhiskeys = async (page) => {
        setLoading(true);

        let q;
        // We went to next page
        if (previousPage < page) {
            q = query(
                whiskeyCollection,
                orderBy('taste_date'),
                startAfter(lastDoc),
                limit(perPage)
            );
        }
        // We went to previous page
        else if (previousPage > page) {
            q = query(
                whiskeyCollection,
                orderBy('taste_date'),
                endBefore(firstDoc),
                limit(perPage)
            );
        }
        // Initial load
        else {
            q = query(whiskeyCollection, orderBy('taste_date'), limit(perPage));
        }

        const whiskeySnapshot = await getDocs(q);
        setFirstDoc(whiskeySnapshot.docs[0]);
        setLastDoc(whiskeySnapshot.docs[whiskeySnapshot.docs.length - 1]);

        const data = whiskeySnapshot.docs.map((doc) => doc.data());
        setWhiskeys(data);
        setPreviousPage(page);

        const whiskeySnapshotAll = await getDocs(whiskeyCollection);
        const dataAll = whiskeySnapshotAll.docs.map((doc) => doc.data());
        setTotalRows(dataAll.length);

        setLoading(false);
    };

    if (whiskeys.length === 0) {
        return <div>loading...</div>;
    }

    const handlePageChange = (page) => {
        getWhiskeys(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const whiskeyCollection = collection(db, 'whiskeys');

        const q = query(
            whiskeyCollection,
            orderBy('taste_date'),
            startAt(page * newPerPage),
            limit(newPerPage)
        );

        const whiskeySnapshot = await getDocs(q);
        const data = whiskeySnapshot.docs.map((doc) => doc.data());
        setWhiskeys(data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    return (
        <>
            <h2 className="whiskeys-headers">- All the 🥃 I have tasted -</h2>
            {/* {whiskeys.map((whiskey) => {
                return (
                    <div key={whiskey.uid} className="whiskeys-group">
                        <h3>
                            <Link to={`/whiskey/${whiskey.uid}`}>
                                {whiskey.name} - ({whiskey.abv}% abv) &gt;
                            </Link>
                        </h3>
                    </div>
                );
            })} */}
            <DataTable
                columns={columns}
                data={whiskeys}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
        </>
    );
};

/**
 * abv
 * add_notes
 * appearance
 * finish
 * name
 * nose
 * palate
 * taste_date
 * uid
 */

export default Whiskeys;
