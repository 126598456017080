import './Dashboard.css';

const Dashboard = () => {
    return (
        <div className="dashboard-container">
            <button>Whiskey Dashboard</button>
            <button>Blog Dashboard</button>
        </div>
    );
};

export default Dashboard;
