import './Home.css';
import { SiYoutube, SiTwitter, SiGithub, SiLinkedin } from 'react-icons/si';

const Home = () => {

    return (
        <div className="home-container">
            <h1 className="home-title">
                Hello! My name is{' '}
                <span className="underlined-text">Austin</span>
            </h1>
            <p className="my-description">
                I am a Software Engineer from Colorado with experience
                developing <span className="underlined-text">cloud-based</span>
                &nbsp;applications and infrastructure in{' '}
                <span className="underlined-text">AWS and Firebase</span>.
            </p>
            <p className="my-description">
                In my free time, I enjoy spending time with my wife, cats, and
                also trying new&nbsp;
                <span className="underlined-text">Whisk(e)y</span> from all
                around the world!
            </p>
            <p className="my-description">
                Please click around to learn more. Cheers 🥃
            </p>
            <ul className="social-links">
                <li>
                    <a href="https://twitter.com/austincobbs_dev">
                        <SiTwitter />
                    </a>
                </li>
                <li>
                    <a href="https://youtube.com/smallbatchdevs">
                        <SiYoutube />
                    </a>
                </li>
                {/* Todo: Update these link */}
                <li>
                    <a href="https://github.com/tigbeans">
                        <SiGithub />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/austin-cobbs-ab51b8154/">
                        <SiLinkedin />
                    </a>
                </li>
            </ul>
            <div className="home-pic-container">
                <img
                    src="assets/images/IMG_6148.PNG"
                    alt="Me Trying To Be Cool TM"
                ></img>
            </div>
        </div>
    );
};

export default Home;
