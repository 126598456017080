import './Blogs.css';
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const db = getFirestore();

    useEffect(() => {
        getBlogs().then((data) => {
            setBlogs(data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBlogs = async () => {
        const blogsCollection = collection(db, 'blogs');
        const blogsSnapshot = await getDocs(blogsCollection);
        return blogsSnapshot.docs.map((doc) => doc.data());
    };

    if (blogs.length === 0) {
        return (
            <>
                <h2>Blog Posts Coming Soon!</h2>
            </>
        );
    } else {
        return (
            <>
                <h2>- My Blog Posts 👨🏽‍💻 -</h2>
                {blogs.map((blog) => {
                    return (
                        <div key={blog.uid} className="blogs-group">
                            <h3>
                                <Link to={`/blog/${blog.uid}`}>
                                    {blog.title} &gt;
                                </Link>
                            </h3>
                            <p className="last-edited">
                                Last Edited:{' '}
                                {new Date(
                                    blog.last_updated.toDate()
                                ).toLocaleString()}
                            </p>
                            <p className="description">{blog.description}</p>
                        </div>
                    );
                })}
            </>
        );
    }
};

export default Blogs;
