import './Login.css';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

const Login = () => {
    const auth = getAuth();

    const history = useHistory();

    const [creds, setCreds] = useState({
        email: '',
        password: '',
    });

    const handleLogin = async (e) => {
        e.preventDefault();
        await signInWithEmailAndPassword(auth, creds.email, creds.password)
            .then((res) => {
                history.push('/');
            })
            .catch((err) => {
                console.log('error signing in', err);
            });
    };

    const handleLogOut = async () => {
        await signOut(auth)
            .catch((err) => {
                console.log('error logging out', err);
            });
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setCreds((prevState) => ({ ...prevState, [id]: value }));
    };

    if (auth.currentUser) {
        return (
            <>
                <button onClick={handleLogOut}>Log Out</button>
            </>
        );
    } else {
        return (
            <>
                <form onSubmit={handleLogin}>
                    <input
                        id="email"
                        type="text"
                        placeholder="Email"
                        value={creds.email}
                        onChange={handleChange}
                    />
                    <input
                        id="password"
                        type="password"
                        placeholder="Password"
                        value={creds.password}
                        onChange={handleChange}
                    />
                    <button type="submit">Log In</button>
                </form>
            </>
        );
    }
};

export default Login;
