import './Blog.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, getDoc, doc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';

const Blog = () => {
    const { uid } = useParams();
    const db = getFirestore();
    const history = useHistory();

    const [post, setPost] = useState([]);

    useEffect(() => {
        getBlogPost().then((post) => {
            setPost(post);
        }, []);
    });

    const backButton = () => {
        history.push('/whiskey');
    };

    const getBlogPost = async () => {
        const postsCollection = doc(db, 'blogs', uid);
        const postSnapshot = await getDoc(postsCollection);
        return postSnapshot.data();
    };

    return (
        <div>
            <h3 className="post-title">{post.title}</h3>
            <p className="post-date">
                Last edited on:{' '}
                {new Date(post.last_updated?.toDate()).toLocaleDateString()}
            </p>
            <div
                className="post-content"
                dangerouslySetInnerHTML={{ __html: post.content }}
            ></div>
            <button className="back-button" onClick={() => backButton()}>
                Back
            </button>
        </div>
    );
};

export default Blog;
