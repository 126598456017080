import './BlogCreate.css';
import RichTextEditor from '../../utils/rich-text-editor/RichTextEditor';
import {
    getFirestore,
    collection,
    setDoc,
    doc,
    Timestamp,
} from 'firebase/firestore';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const BlogCreate = () => {
    const [blog, setBlog] = useState({});

    const history = useHistory();
    const db = getFirestore();
    const blogCollection = collection(db, 'blogs');

    const handleSubmit = (event) => {
        event.preventDefault();
        blog.last_updated = Timestamp.now();
        blog.created_on = Timestamp.now();
        setDoc(doc(blogCollection, blog.uid), blog)
            .then(() => history.goBack())
            .catch((err) => {
                console.error('BlogCreate.handleSubmit() Error::', err);
            });
    };

    const handleEditorCallback = (editorMarkup) => {
        setBlog({ ...blog, content: editorMarkup });
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setBlog((values) => ({ ...values, [name]: value }));
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="blog-create-form">
                {/* UID (also pretty url) */}
                <label>UID</label>
                <input
                    type="text"
                    name="uid"
                    value={blog.uid || ''}
                    onChange={handleChange}
                />

                {/* Title */}
                <label>Title</label>
                <input
                    type="text"
                    name="title"
                    value={blog.title || ''}
                    onChange={handleChange}
                />

                {/* Description */}
                <label>Description</label>
                <input
                    type="text"
                    name="description"
                    value={blog.description || ''}
                    onChange={handleChange}
                />

                <RichTextEditor parentCallback={handleEditorCallback} />
                <button className="blog-add-button" type="submit">
                    Submit
                </button>
            </form>
        </>
    );
};

export default BlogCreate;
