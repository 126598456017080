import './BlogDashboard.css';
import { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link, useHistory } from 'react-router-dom';

const BlogDashboard = () => {
    const [blogs, setBlogs] = useState([]);
    const db = getFirestore();
    const blogCollection = collection(db, 'blogs');

    const history = useHistory();

    useEffect(() => {
        getBlogs().then((data) => {
            setBlogs(data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBlogs = async () => {
        const blogsSnapshot = await getDocs(blogCollection);
        return blogsSnapshot.docs.map((doc) => doc.data());
    };

    const handleCreate = () => {
        history.push('/blog-dashboard/create');
    };

    return (
        <>
            <button className="blog-add-button" onClick={() => handleCreate()}>
                Add New +
            </button>
            {blogs.map((blog) => {
                return (
                    <div key={blog.uid} className="blogs-group">
                        <h3>
                            <Link to={`/blog-dashboard/edit/${blog.uid}`}>
                                {blog.title} &gt;
                            </Link>
                        </h3>
                    </div>
                );
            })}
        </>
    );
};

export default BlogDashboard;
