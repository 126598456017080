import './Whiskey.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, getDoc, doc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';

const Whiskey = () => {
    const { uid } = useParams();
    const db = getFirestore();
    const history = useHistory();

    const [whiskey, setWhiskey] = useState([]);

    useEffect(() => {
        getWhiskey().then((whiskey) => {
            setWhiskey(whiskey);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getWhiskey = async () => {
        const whiskeyCollection = doc(db, 'whiskeys', uid);
        const whiskeySnapshot = await getDoc(whiskeyCollection);
        return whiskeySnapshot.data();
    };

    const backButton = () => {
        history.push('/whiskey');
    };

    return (
        <div key={whiskey.uid}>
            <h3 className="whiskey-name">
                {whiskey.name} - ({whiskey.abv}% abv)
            </h3>
            <p className="taste-date">
                Enjoyed on:{' '}
                {new Date(whiskey.taste_date?.toDate()).toLocaleDateString()}
            </p>
            <div className="whiskey-notes">
                <p>Appearance: {whiskey.appearance}</p>
                <p>Nose: {whiskey.nose}</p>
                <p>Palate: {whiskey.palate}</p>
                <p>Finish: {whiskey.finish}</p>
                <p>Additional Notes: {whiskey.add_notes}</p>
            </div>
            <button className="back-button" onClick={() => backButton()}>
                Back
            </button>
        </div>
    );
};

/**
 * abv
 * add_notes
 * appearance
 * finish
 * name
 * nose
 * palate
 * taste_date
 * uid
 */

export default Whiskey;
