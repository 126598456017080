import './App.css';
import { React } from 'react';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { firebaseConfig } from './config/config';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import Blogs from './components/blog/Blogs';
import Blog from './components/blog/Blog';
import Resume from './components/resume/Resume';
import Whiskeys from './components/whiskey/Whiskeys';
import Whiskey from './components/whiskey/Whiskey';
import Login from './components/login/Login';
import Dashboard from './components/dashboards/Dasboard';
import WhiskeyDashboard from './components/dashboards/whiskey/WhiskeyDashboard';
import WhiskeyCreate from './components/dashboards/whiskey/WhiskeyCreate';
import WhiskeyEdit from './components/dashboards/whiskey/WhiskeyEdit';
import BlogDashboard from './components/dashboards/blog/BlogDashboard';
import BlogCreate from './components/dashboards/blog/BlogCreate';
import BlogEdit from './components/dashboards/blog/BlogEdit';
import { getFirestore, connectFirestoreEmulator } from '@firebase/firestore';

const firebaseApp = initializeApp(firebaseConfig);
// console.log('process.env.NODE_ENV', process.env.NODE_ENV);

// Cloud Firestore not available for web yet
// const appCheck = initializeAppCheck(firebaseApp, {
//     provider: new ReCaptchaV3Provider(
//         '6LddolQeAAAAADMjwRsbUKOui_KcpD1CQwUdj8Ik'
//     ),
//     isTokenAutoRefreshEnabled: true,
// });
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
if (process.env.NODE_ENV === 'development') {
    console.log('using emulator');
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFirestoreEmulator(db, 'localhost', 8081);
}

const App = () => {
    const [user, loading, error] = useAuthState(auth);
    if (loading) {
        return (
            <div>
                <p>Loading user...</p>
            </div>
        );
    }
    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
            </div>
        );
    }
    return (
        <div className="main-container">
            <BrowserRouter>
                <Navbar />
                <div className="content-container">
                    <Switch className="content-container">
                        <Route component={Blog} path="/blog/:uid" />
                        <Route component={Blogs} path="/blog" />
                        <Route component={Resume} path="/resume" />
                        <Route component={Whiskey} path="/whiskey/:uid" />
                        <Route component={Whiskeys} path="/whiskey" />
                        <Route component={Login} path="/login" />
                        <PrivateRoute
                            component={Dashboard}
                            authed={user ? true : false}
                            path="/dashboard"
                        />
                        <PrivateRoute
                            component={BlogCreate}
                            authed={user ? true : false}
                            path="/blog-dashboard/create"
                        />
                        <PrivateRoute
                            component={BlogEdit}
                            authed={user ? true : false}
                            path="/blog-dashboard/edit/:uid"
                        />
                        <PrivateRoute
                            component={BlogDashboard}
                            authed={user ? true : false}
                            path="/blog-dashboard"
                        />
                        <PrivateRoute
                            component={WhiskeyCreate}
                            authed={user ? true : false}
                            path="/whiskey-dashboard/create"
                        />
                        <PrivateRoute
                            component={WhiskeyEdit}
                            authed={user ? true : false}
                            path="/whiskey-dashboard/edit/:uid"
                        />
                        <PrivateRoute
                            component={WhiskeyDashboard}
                            authed={user ? true : false}
                            path="/whiskey-dashboard"
                        />
                        {/* <Route component={Projects} path='/projects' /> */}
                        <Route component={Home} pattern="/" />
                    </Switch>
                </div>
            </BrowserRouter>
        </div>
    );
};

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                authed ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default App;
