import './Navbar.css';
import { useState } from 'react';
import { CgMenuCake, CgClose } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

const Navbar = () => {
    const auth = getAuth();
    const [user, loading] = useAuthState(auth);
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
        if (window.innerWidth <= 768) {
            setSidebar(!sidebar);
        }
    };

    return (
        <div className="navbar">
            <Link className="header-logo" to="/">
                {/* <span className='logo-svg'>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" viewBox="107.1408 305.6655999999999 58.66879999999999 30.67520000000013" width="64" height="31.23"><defs><path d="M162.81 333.34C151.89 333.34 145.82 333.34 144.61 333.34C141.7 333.34 139 331.77 137.57 329.23C136.68 327.65 129.55 315.02 128.66 313.44C128.19 312.61 127.31 312.09 126.36 312.09C125.34 312.09 124.43 312.66 123.98 313.57C123.33 314.89 120.07 321.48 114.2 333.34L108.14 333.34C114.73 320.03 118.39 312.64 119.12 311.16C120.49 308.39 123.26 306.67 126.36 306.67C129.27 306.67 131.96 308.24 133.39 310.77C134.28 312.35 141.41 324.98 142.3 326.56C142.77 327.4 143.65 327.91 144.61 327.91C145.82 327.91 151.89 327.91 162.81 327.91L162.81 333.34Z" id="b6rVQCWu41"></path><path d="M162.81 312.09C151.89 312.09 145.82 312.09 144.61 312.09C143.65 312.09 142.77 312.61 142.3 313.44C142.15 313.7 141.42 315 140.1 317.35L136.98 311.83C137.34 311.2 137.53 310.84 137.57 310.77C139 308.24 141.7 306.67 144.61 306.67C145.82 306.67 151.89 306.67 162.81 306.67L162.81 312.09Z" id="bER8edIIg"></path><path d="M118.88 333.34L126.44 318.55L134.07 333.34L118.88 333.34Z" id="a1DVeHDKni"></path></defs><g><g><use xlinkHref="#b6rVQCWu41" opacity="1" fill="#000000" fill-opacity="1"></use><g><use xlinkHref="#b6rVQCWu41" opacity="1" fill-opacity="0" stroke="#000000" stroke-width="1" stroke-opacity="0"></use></g></g><g><use xlinkHref="#bER8edIIg" opacity="1" fill="#000000" fill-opacity="1"></use><g><use xlinkHref="#bER8edIIg" opacity="1" fill-opacity="0" stroke="#000000" stroke-width="1" stroke-opacity="0"></use></g></g><g><use xlinkHref="#a1DVeHDKni" opacity="1" fill="#000000" fill-opacity="1"></use><g><use xlinkHref="#a1DVeHDKni" opacity="1" fill-opacity="0" stroke="#000000" stroke-width="1" stroke-opacity="0"></use></g></g></g></svg>

                </span> */}

                <span className="text-thin">austin</span>
                <span className="text-bold">cobbs</span>
            </Link>
            <CgMenuCake
                className={`hamburger-icon ${
                    sidebar ? 'hamburger-icon-inactive' : ''
                }`}
                onClick={showSidebar}
            />

            <div className={`nav-menu ${sidebar ? 'sidebar-active' : ''}`}>
                <CgClose className="close" onClick={showSidebar}></CgClose>
                <ul>
                    <li className="nav-menu-item home-nav-menu-item">
                        <Link onClick={showSidebar} to="/">
                            Home
                        </Link>
                    </li>
                    <li className="nav-menu-item">
                        <Link onClick={showSidebar} to="/blog">
                            Blog
                        </Link>
                    </li>
                    <li className="nav-menu-item">
                        <Link onClick={showSidebar} to="/resume">
                            Resume
                        </Link>
                    </li>
                    <li className="nav-menu-item">
                        <Link onClick={showSidebar} to="/whiskey">
                            Whiskey
                        </Link>
                    </li>
                    {user && !loading && (
                        <>
                            <li className="nav-menu-item">
                                <Link
                                    onClick={showSidebar}
                                    to="/blog-dashboard"
                                >
                                    Blog Dash
                                </Link>
                            </li>
                            <li className="nav-menu-item">
                                <Link
                                    onClick={showSidebar}
                                    to="/whiskey-dashboard"
                                >
                                    Whiskey Dash
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
