import './Resume.css';

const Resume = () => {
    return (
        <div className="resume-component">
            <div className="resume-group">
                <div className="resume-item">
                    <h3>Languages.</h3>
                    <p>Java, Javscript/Typescript, HTML, CSS/SCSS, Bash</p>
                </div>
                <hr />
                <div className="resume-item">
                    <h3>Technologies.</h3>
                    <p>
                        AWS (Serverless, ECS, DevOps), Firebase, Spring Boot,
                        Angular, React, PostgreSQL, Git, Atlassian products,
                        Linux, Spring/Hibernate, JSON/XML, Docker, RxJS
                    </p>
                </div>
                <hr />
                <div className="resume-item">
                    <h3>Processes & Concepts.</h3>
                    <p>
                        Agile, SCRUM, AWS Well Architected Framework,
                        Unit/Integration testing
                    </p>
                </div>
                <hr />
                <div className="resume-item">
                    <h3>Certifications.</h3>
                    {/* TODO: insert credly link here */}
                    <p>AWS Certified Cloud Practitioner (Expires Sep 2024)</p>
                </div>
            </div>
            <hr />
            <div className="resume-group">
                <h3 className="experience-header">Experience.</h3>
                <div className="experience-group">
                    <p className="timeline">March 2021 - PRESENT</p>
                    <p className="company">
                        Starz Entertainment LLC - Software Engineer / Cloud
                        Engineer
                    </p>
                    <div className="job-duty">
                        <h4 className="work-header">Job Duties.</h4>
                        <ul>
                            <li>
                                <p>
                                    Updated AWS infrastructure from being
                                    deployed in a single region to multiple
                                    regions providing better uptime and a
                                    failover option.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Wrote and maintained AWS Infrastructure as
                                    code using CloudFormation (and SAM).
                                </p>
                            </li>
                            <li>
                                <p>
                                    Wrote and maintained maintained many Lambdas
                                    using Typescript to achieve functionality
                                    such as interacting with s3 buckets,
                                    redirecting routes, modifying headers,
                                    clearing CloudFront cache, and more.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="experience-group">
                    <p className="timeline">January 2021 - March 2021</p>
                    <p className="company">
                        DSoft Technology - Cloud Engineer / DevSecOps Engineer /
                        Software Developer
                    </p>
                </div>
                <div className="experience-group">
                    <p className="timeline">June 2018 - January 2021</p>
                    <p className="company">
                        Parsons - Software Engineer - DOD Secret Clearance
                    </p>
                    <div className="job-duty">
                        <h4 className="work-header">Backend Development.</h4>
                        <ul>
                            <li>
                                <p>
                                    Utilized Java, Spring Boot, and PostgreSQL
                                    to build back-end services and APIs.
                                    Deployed the services to a Docker
                                    environment.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Frequently chosen for more obscure
                                    problem-solving tasks such as deploying and
                                    setting up new environments, integrating
                                    multiple apps into a single environment,
                                    integrating OAuth and Websockets.
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="job-duty">
                        <h4 className="work-header">
                            AWS (GovCloud) Administration.
                        </h4>
                        <ul>
                            <li>
                                <p>
                                    Managed/created hundreds of AWS resources
                                    including (VPCs, Subnets, Security Groups,
                                    EC2 Instances, etc.)
                                </p>
                            </li>
                            <li>
                                <p>
                                    Utilized Terraform (infrastructure as code)
                                    to build and manage AWS resources.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Utilized Python, Flask, and Angular to pull
                                    EC2 Instance metadata from AWS and present
                                    that data to an end-user so that users could
                                    start/stop their application and development
                                    EC2 Instances without having to contact an
                                    AWS admin.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Wrote Python script to automatically
                                    refactor 1500+ Terraform managed resources
                                    into smaller and more manageable pieces.
                                    This process reduced the Terraform build
                                    time by over 50%.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Resume;
